import React, { useEffect } from 'react';
import styled from 'styled-components';
import JotformEmbed from 'react-jotform-embed';

import { Section, Container } from '@components/global';

const Contact = () => {
  return (
	<Section id="contato" accent="primary">
      <Container style={{ position: 'relative' }}>
		<Form>
		  <h1> Contato </h1>
		  <JotformEmbed scrolling={false} src="https://jotform.com/232423557762055" />
		  <Overlay />
		</Form>
      </Container>
	</Section>
  );
}

const Form = styled.div`
  position: relative;
  color: ${props => props.theme.color.white.dark};
`

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 110px;
  background-color: #393E46;
  z-index: 10;
`

export default Contact;
