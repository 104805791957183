import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import CookieConsent from 'react-cookie-consent';

import Header from '@sections/Header';
import About from '@sections/About';
import Contact from '@sections/Contact';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
	<CookieConsent
	  style={{ backgroundColor: "#3F3D56" }}
	  buttonStyle={{ backgroundColor: "#797789", color: "#eee" }}
      location="bottom"
      buttonText="OK"
      cookieName="coruja-cookies-consent">
	  Esse site utiliza cookies. Nós armazenamos dados temporariamente para melhorar sua experiência e nossos serviços. Ao navegar por este site você concorda com a <a style={{color: "#e0e0e0"}} href="/privacidade"> Política de Privacidade </a>.
	</CookieConsent>

    <Navbar />
    <Header />
    <About />
    <Faq />
	<Contact />
    <Footer />
  </Layout>
);

export default IndexPage;
