import React, { Component, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import Img from 'gatsby-image';
import { StaticQuery, useStaticQuery, graphql } from "gatsby"

import { Container } from '@components/global';
import {
  Nav,
  NavItem,
  Brand,
  BrandText,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from './style';

import { ReactComponent as MenuIcon } from '@static/icons/menu.svg';

//const NAV_ITEMS = ['Sobre', 'Brands', 'Team', 'FAQ', 'Blog'];
const NAV_ITEMS = ['Pilares', 'Serviços', 'Contato'];

const Navbar = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  const closeMobileMenu = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  const getNavAnchorLink = item => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={closeMobileMenu}>
      {item}
    </AnchorLink>
  );

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  return (
	<StaticQuery
	  query={graphql`
            query {
              logo: file(
                sourceInstanceName: { eq: "logos" }
                name: { eq: "logo" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 55) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
    `}
      render={data => (
      <Nav {...props}>
	  <StyledContainer>
	    <Brand onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
          <Img
			alt="logo"
			fluid={data.logo.childImageSharp.fluid}
			style={{ marginRight: '10px', width: '40px', height: '40px' }}
		  />
		  <BrandText>coruja</BrandText>
		</Brand>
        <Mobile>
          <button onClick={toggleMobileMenu} style={{ color: 'white' }}>
            <MenuIcon />
          </button>
        </Mobile>

        <Mobile hide>{getNavList({})}</Mobile>
      </StyledContainer>
        <Mobile>
          {mobileMenuOpen && (
            <MobileMenu>
              <Container>{getNavList({ mobile: true })}</Container>
            </MobileMenu>
          )}
        </Mobile>
      </Nav>
	  )}
	/>
  );
}

export default Navbar;
