import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'Arquitetura de Software',
    content: () => (
      <>
          • Projeto e implementação de arquiteturas orientadas a serviços
          <br />
          <br />
          • Evolução e Migração de serviços legados
          <br />
          <br />
		  • Projetos para diminuir dívidas técnicas
      </>
    ),
  },
  {
    title: 'Developer Experience',
    content: () => (
      <>
          • Implementação de <i>Pipelines</i> de Entrega Contínua e GitOps
          <br />
          <br />
          • Provisionamento de ambientes em Google Cloud e AWS
          <br />
          <br />
          • Implantação de sistemas de Observabilidade (Logs, Métricas e Distributed Tracing)
          <br />
          <br />
          • Instrumentação de aplicações para Monitoramento e Alertas
      </>
    ),
  },
  {
    title: 'Engenharia de Software',
    content: () => (
      <>
		• Cursos e treinamentos para seu time de tecnologia
		<br />
        <br />
		• Mentoria nos processos e cultura de engenharia
		<br />
        <br />
		• Descoberta, modelagem e documentação de domínios
      </>
    ),
  },
];

const Faq = () => (
  <Section id="serviços" accent="secondary">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Serviços</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
	  <br/>
	  <h3>E mais...</h3>
    </Container>
  </Section>
);

export default Faq;
